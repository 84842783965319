<script lang="ts" setup>
import type { NotificationMessage, NotificationMessagePayload } from "@/types";

const { t } = useT();
const { initAndOpen } = useSupportChat();

const props = defineProps<{
	unreadNotifications: NotificationMessage[];
	readNotifications: NotificationMessage[];
}>();
const emit = defineEmits<{
	(event: "closePopup"): void;
	(event: "setStatusRead", payload: number[]): void;
	(event: "updateNotificationStatus", payload: number[]): void;
	(event: "clickOnMessage", payload: NotificationMessagePayload): void;
}>();

const hasMessages = computed(() => props.unreadNotifications.length || props.readNotifications.length);
const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "widget",
		button_name: "support"
	});
	initAndOpen();
};

const handleClickMessage = (payload: NotificationMessagePayload) => {
	emit("clickOnMessage", payload);
};

onMounted(() => {
	if (document?.body) {
		document.body.style.overflow = "hidden";
	}

	dispatchGAEvent({
		event: "tooltips",
		type: props.unreadNotifications.length ? "new" : "empty",
		location: "header",
		form_name: "notifications"
	});

	if (!props.unreadNotifications.length) {
		return;
	}

	const unreadOfferIds = props.unreadNotifications.reduce<number[]>((acc, item) => {
		if (item.type === "offer" && new Date(item.dateEnd).getTime() < new Date().getTime()) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadOfferIds.length) {
		emit("setStatusRead", unreadOfferIds);
	}
});
onUnmounted(() => {
	if (document?.body) {
		document.body.style.overflow = "initial";
	}

	if (!props.unreadNotifications.length) {
		return;
	}

	const unreadIds = props.unreadNotifications.reduce<number[]>((acc, item) => {
		if (!item.link) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadIds.length) {
		emit("updateNotificationStatus", unreadIds);
	}
});
</script>
<template>
	<APopper show>
		<template #content>
			<div class="notification-popup">
				<div class="header">
					<AText variant="toledo" class="text-cannes title" :modifiers="['semibold']">
						{{ t("InBox") }}

						<ABadge v-if="unreadNotifications.length" variant="info" background="var(--chimoio)">
							<AText class="text-cannes" :modifiers="['medium']">{{ unreadNotifications.length }}</AText>
						</ABadge>
					</AText>
					<NuxtIcon class="close-icon" name="16/close" filled @click.stop="$emit('closePopup')" />
				</div>

				<div class="content">
					<template v-if="hasMessages">
						<div v-if="unreadNotifications.length" class="unread-wrapper text-cannes">
							<AText class="title" :modifiers="['medium']">{{ t("New") }}</AText>
							<MNotificationCenterItem
								v-for="item in unreadNotifications"
								:key="`unread-${item.ncMessageId}`"
								:data="item"
								@click-message="handleClickMessage"
							/>
						</div>
						<div v-if="readNotifications.length" class="text-cannes">
							<AText class="title text-conakry" :modifiers="['medium']">{{ t("Earlier") }}</AText>
							<MNotificationCenterItem
								v-for="item in readNotifications"
								:key="`read-${item.ncMessageId}`"
								:data="item"
								@click-message="handleClickMessage"
							/>
						</div>
					</template>
					<AText v-else as="div" class="no-messages text-conakry" :modifiers="['center']">
						{{ t("Here will be your messages") }}
					</AText>
				</div>

				<div class="footer">
					<AText variant="topeka" :modifiers="['light']">
						{{ t("Messages older than 14 days are deleted for you.") }}
					</AText>
					<AText :modifiers="['link', 'capitalize', 'underline']" @click="handleClickOpenSupport">
						{{ t("Customer care") }}
					</AText>
				</div>
			</div>
		</template>
	</APopper>
</template>
<style scoped lang="scss">
.notification-popup {
	width: 360px;
	max-height: 594px;
	cursor: auto;

	@include media-breakpoint-down(lg) {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		max-height: none;
	}

	&:deep(svg) {
		filter: none !important;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: gutter(1.25) gutter(2);
		line-height: 24px;

		.title {
			display: inline-flex;
			align-items: center;
			gap: gutter(0.5);
		}
	}

	.unread-count {
		margin-left: gutter(1);
		padding: gutter(0.125) gutter(0.5);
		border-radius: 5px;
		background: var(--camaguey);
	}

	.close-icon {
		font-size: 22px;
		display: flex;
		margin-left: gutter(2);
		cursor: pointer;

		&:deep(svg) {
			margin-bottom: 0;
		}
	}

	.content {
		max-height: 488px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--scrollbar-thumb-background);
			border-radius: 10px;
			width: 4px;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		.title {
			padding: gutter(2) gutter(2);
			display: flex;
		}

		@include media-breakpoint-down(lg) {
			max-height: 100%;
		}
		@media (max-height: 500px) and (orientation: landscape) {
			max-height: 100%;
		}
	}

	.unread-wrapper {
		background-color: var(--curug);
	}

	.no-messages {
		padding: gutter(2);
		p {
			margin-top: gutter(0.5);
		}
	}

	.footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: gutter(3);
		padding: gutter(1.5) gutter(2);
		background: var(--cuttack);
		border-top: 1px solid var(--changshu);
		color: #9aa0b1;

		.link {
			white-space: nowrap;
		}
	}
}
</style>
